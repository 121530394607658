// .bestSpots {
//     font-family: "Avenir";
//     h3 {
//         margin-top: 0;
//         text-align: center;
//         font-size: 1.7em;
//     }
// }

$filterPicker-height: 329.59px;
$edge-margin: 10px;

.settingsCard:nth-child(2n) {
    float: right;
    width: 49%;
}

.settingsCard {
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: rgba(0, 0, 0, 0.301);
    border: 0;
    position: relative;
    border-radius: 10px;
    float: left;
    width: 49%;

    .card-body {
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
    }
    .toggleTextLeft {
        margin: 0;
        font-size: 0.7rem;
    }
    .toggleTextRight {
        margin: 0;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
    }
}

.settingSelector{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    
    background-color: rgba(255,255,255,0);
    border: none;
    text-align: center;
    color: rgba(255,255,255,0)!important;
    color: inherit;
    font-family: inherit;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;
    option {
        color: #242424;
    }
}

.settingSelector::-ms-expand {
    display: none;
}



.sidebar-right {
    font-family: "Avenir", Arial, Helvetica, sans-serif;
    
    h3{
        margin-top: 10px;
        text-align: center;
        font-size: 1.7em;
        margin-bottom: 0.5em;
    }

    #fiskePladserHeadline {
        margin-top:1em;
    }

    .spotListItem {
        padding: 1em;
        font-family: "Avenir", Arial, Helvetica, sans-serif;
    }

    .sortingBar {
        font-family: "Avenir", Arial, Helvetica, sans-serif;
        padding: 0 1em;
    }

    .sortingBar span {
        display:inline-block;
        text-align: center;
        width:32.35%;
        font-size: 1.2em;
    }

    .sortingBar .sortArrow {
        width: 10px;
    }

    .spotListItem .dataBar .info {
        display: inline-block;
        text-align: center;
        width: 32.29%;
        vertical-align: middle;
        font-size: 1.2em;
    }
    .spotListItem .dataBar .name {
        font-size: 1.2em;
        font-weight: bold;
        display: block;
        width:100%;
    }
    

    .card{
        cursor: pointer;
        margin-top: 4px;
        margin-bottom: 4px;
        background-color: rgba(0,0,0,0.301);
        border: 0;
        position: relative;
        border-radius: 10px;

        .card-body {
            padding: 15px;

            .coastType {
                position: absolute;
                right: 10px;
                top: 15px;
            }
        }

        .card-title {
            margin-right: 30px;
        }

    }

    .card:hover {
        opacity: 0.5;
    }

}

