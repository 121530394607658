*, *:active, *:hover {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  font-size: 14px;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
}
