.layerPickerContainer {
    overflow: auto;
    /* margin-bottom: 20px; */
    margin-top: 0px!important;
    margin-right: 0px;
    margin-left: 50px;

    .layerPicker {
        font-family: "Avenir", Arial, Helvetica, sans-serif;
        float:right;
        margin: 1px 1px;
        background-color: rgba(35,35,36,0.85);
        border-radius: 0px;
        color: #FFF;
        font-size: 1.2em;
        font-weight: bold;
        width:100px;
        text-align: center;
        // line-height: 40px;
        cursor: pointer;

        .pictogram{
            width: 30px;
            height: 30px;
        }
        p{
            margin: 0;
            margin-top: -10px;
        }
    }

    @media screen and (min-width: 763px) {
        .layerPicker:first-child {
            border-radius: 0px 0px 5px 0px;
        }

        .layerPicker:last-child {
            // margin-right: 10px;
            border-radius: 0px 0px 0px 5px;
        }
    }

    .layerPicker.hidden {
        background-color:#777777c5;
        font-weight: 100;
    }    
}


.layerPickerContainer:after {
    content: "";
    clear: both;
    display: table;
}



@media screen and (max-width: 600px) {
    .layerPickerContainer {
        width:101px;
        margin-right: 0px!important;
        margin-top: 10px!important;

        .layerPicker{
            line-height: 35px;
            height: 35px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .layerPicker p{
            margin-top: 0px!important;
        }

        .layerPicker:first-child {
            border-radius: 5px 0px 0px 0px;
            // margin-top:10px;
        }
        .layerPicker:last-child {
            border-radius: 0px 0px 0px 5px;
        }
        .pictogram{
            display: none;
        }
    }
}