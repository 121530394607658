
#certifiedHotel {
    overflow: scroll;
    height: 90%; /* fallback if needed */
    height: calc(100% - 65px);
    font-family: "Avenir", Arial, Helvetica, sans-serif;


    .hotelImage {
        width:100%;
    }
    .header {
        h4 {
            font-size:0.8em;
        }
        h2 {
            margin:1em 0;
            text-align: center;
        }
    }
    .headlineBar{
        /* Rectangle: */
        background: rgba(0,0,0,0.30);
        border-radius: 4px;
        /* Varieret: */
        font-size: 22px;
        color: #FFFFFF;
        letter-spacing: 0;
        /* Kysttype: */
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        height: 45px;
        margin-bottom: 8px;
        padding-left: 8px;
        padding-right: 8px;
        overflow: hidden;
        text-overflow: ellipsis;

        .label {
            display:block;
            float:left;
            line-height: 45px;
        }

        .data {
            display:block;
            float: right;
            line-height: 45px;
        }
    }

    .facilities{
        h3 {
            margin: 0.8em;
            text-align: center;
        }
        ul {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            padding-inline-start: 15px;
        }
    }
    a{
        color: #FFF;
    }
}

.buttonContainer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;

    .navButton {
        background: #FFF;
        background-image: linear-gradient(-180deg, #FFFFFF 0%, rgba(229,243,252,0.28) 100%);
        box-shadow: 0 6px 11px 0 #85989F, inset 0 2px 2px 0 #FFFFFF;
        border-radius: 4px;
        font-family: HelveticaNeue-Bold, "Avenir", Arial, Helvetica, sans-serif;;
        font-size: 16px;
        font-weight: bold;
        color: #3E94F8;
        letter-spacing: 0;
        width: 100%;
        height: 55px;
        text-align: center;
        cursor: pointer;
        //margin: 5%;
    }
}
