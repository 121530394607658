#sidebar .close {
    right:10px!important;
    top:10px!important;
}
.sidebarInner{
    height: 100%;
    width: 100%;
    overflow: auto;
    background: rgba(35, 35, 36, 0.85);
    border-radius: 10px;
    padding: 8px;
}


#spotDescription {
    overflow: scroll;
    // position: absolute;
    // bottom: 75px;
    // top: 0;
    // left: 0;
    // right: 0;
    // margin:10px;
    height: 90%; /* fallback if needed */
    height: calc(100% - 65px);
    // padding-bottom: 55px;
    font-family: "Avenir", Arial, Helvetica, sans-serif;
    // padding-right: 7px;

    .windIcon {
        height: 1em;
    }

    .descriptionHeadline{
        text-align: center; 
        margin-top: 20px;
        font-size: 1.2em;
    }
    .description {
        text-align: justify;
        font-size: 1.1em;
        line-height: 1.5;
        margin-top: 10px;
        padding-left: 4px;
        padding-right: 4px;
    }
    

    .header {
        h4 {
            font-size:0.8em;
        }
        h2 {
            margin:1em 0;
            text-align: center;
        }
    }

    .headlineBar{
        /* Rectangle: */
        background: rgba(0,0,0,0.30);
        border-radius: 4px;
        /* Varieret: */
        font-size: 22px;
        color: #FFFFFF;
        letter-spacing: 0;
        /* Kysttype: */
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        height: 45px;
        margin-bottom: 8px;
        padding-left: 8px;
        padding-right: 8px;
        overflow: hidden;
        text-overflow: ellipsis;

        .label {
            display:block;
            float:left;
            line-height: 45px;
        }

        .data {
            display:block;
            float: right;
            line-height: 45px;
        }

        .directions {
            font-size: 14px;
        }
    }

    .fishTypes {
        background: rgba(0,0,0,0.30);
        border-radius: 4px;
        /* Varieret: */
        font-size: 22px;
        color: #FFFFFF;
        letter-spacing: 0;
        /* Kysttype: */
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        margin-bottom: 8px;
        padding:8px;
        min-height:45px;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        .data {
            display:inline-block;
            float:right;
            line-height: 45px;
            .fishType {
                // line-height: 16px;
                margin-left: 2px;
            }
        }

        .label {
            display: block;
            /* vertical-align: middle; */
            line-height: 30px;
            /* margin-right: 30px; */
            position: absolute;
            top: 50%;
            margin-top: -15px;
        }
        
    }
}

.seasonbar {
    overflow:auto;
    padding-right:0px;
    position:relative;

    p {
        font-size:0.5em;
        margin-top:5px;
    }

    #explanation {
        position:absolute;
        right:0;
        top:0;
        width:100px;
        padding-left:10px;
        font-size: 0.7em;

        p {
            margin:0;
            padding:0;
        }
    }

    .now {
        position: absolute;
        top:0;
        height:100%;
        width: 4px;
        background: #eee;
        opacity: 0.8;

        margin-left: -2px;
        border-radius: 1px;
    }


    #months{
        width:100%;
        margin-top: -3px;
    }

    #months p{
        float: left;
        width: 8.33%;
        box-sizing:border-box;
        text-align: center;
    }

    #seasonContainer {
        width:100%;
        height: 45px;
        background:rgba(0,0,0,0.30);
        position:relative;
        border-radius: 5px;
        overflow:hidden;

        .month {
            display: block;
            width: 8.333%;
            float: left;
            text-align: center;
            line-height: 45px;
            position: relative;

            .monthSeason {
                position: relative;
                z-index: 1000;
            }

            .protecFirstMonth {
                position: absolute;
                top:0; bottom: 0;
                right:0;left:50%;
                background: rgba(234, 105, 105, 1);
            }

            .protecLastMonth {
                position: absolute;
                top:0; bottom: 0;
                right:50%;left:0;
                background: rgba(234, 105, 105, 1);
            }
        }

        .month.high {
            background:#558857;
        }

        .month.middle {
            background: #88a079;
        }

        .month.protected {
            background:rgba(234, 105, 105, 1);
        }    
    }
}

#seasonDescription {
    overflow: auto;
    div div {
        float:left;
        width: 50%;
        text-align: center;
        margin-top: 5px;
    }

    >div {
        overflow: auto;
        width:50%;
        float:left;
    }

    span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
    }

    @media (max-width: 1200px) {
        >div {
            width:100%;
        }

        div div {
            width: 50%;
            text-align: left;
        }
    }
}
.buttonContainer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;

    .navButton {
        background: #FFF;
        background-image: linear-gradient(-180deg, #FFFFFF 0%, rgba(229,243,252,0.28) 100%);
        box-shadow: 0 6px 11px 0 #85989F, inset 0 2px 2px 0 #FFFFFF;
        border-radius: 4px;
        font-family: HelveticaNeue-Bold, "Avenir", Arial, Helvetica, sans-serif;;
        font-size: 16px;
        color: #3E94F8;
        letter-spacing: 0;
        width: 100%;
        height: 55px;
        text-align: center;
        cursor: pointer;
        //margin: 5%;
    }
}
