$width: 500px;

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(193, 193, 193, 0.24);
    //-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
::-webkit-scrollbar-corner{
    display:none;
}



.sidebar {
    position: absolute;
    left: 0;
    bottom:0;
    top:0;
    z-index: 2000;
    color: #FFF;
    transition: all 0.3s ease;
    border-radius: 10px;
    padding:10px;
    font-size: 1em;
    font-family:  "Avenir", Arial, Helvetica, sans-serif;
    height: 100%;   
    -ms-overflow-style: none;

    @media (max-width: 768px){
        width: 100%;
    }
    @media (max-width: 991px) and (min-width: 768px){
        width: 400px;
    }
    @media (max-width: 1199px) and (min-width: 992px) {
        width: 500px;
    }
    @media (min-width: 1199px) {
        width: 540px;
    }


    h2 {
        font-size: 2em;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 31px;
        height: 31px;
        line-height: 31px!important;
        color: #333;
        font-weight: 400;
        text-align: center;
        background: white;
        -webkit-border-radius: 16px;
        border-radius: 16px;
        font: 25px/1 Arial, Helvetica, sans-serif;
        cursor: pointer;
            z-index: 10000;

    }
    .sidebarInner{
        height: 100%;
        width: 100%;
        overflow: auto;
        background: rgba(35, 35, 36, 0.85);
        border-radius: 10px;
        padding: 8px;
    }
}

.sidebar.collapsed {
    left: -100%;
    transition: all 0.5s ease;
    visibility: hidden;
}

.sidebar-right {
    right: 0;
    left: auto;
    .close {
        right: auto;
        top: 20px;
        left: 20px;
        z-index: 10000;
    }
}

.sidebar-right.collapsed {
    left: auto;
    right: -100%;
    transition: all 0.5s ease;
    visibility: hidden;
}




