$borderRadius: 10px;
$height: 34px;

#bestSpotsButton {
    position: absolute;
    right:80px;
    top: 50%;
    z-index: 401;
}
#dayPickerButton{
    position: absolute;
    left: -10px;
    bottom: 20px;
    z-index: 401;
    display: none;
}

.fizzButton{
    width: $height;
    height: $height;
    background: rgba(35,35,36,0.85);
    transition: opacity 0.5s ease;
    color: #FFF;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: $height;
    text-align: center;
    cursor: pointer;

    img {
        height:100%;
        opacity: 0.7;
    }
}
.fizzButton:hover {
    opacity: 0.5;
}

#bestSpotsButton div {
    border-radius: $borderRadius 0 0 $borderRadius;
}

#dayPickerButton div{
    border-radius: 0 $borderRadius $borderRadius 0;
}


@media screen and (max-width: 768px) {
    #bestSpotsButton {
        right:0;
        top: calc(50% - 17px);
    }
    #dayPickerButton{
        display: block;
    }

}