.mapPickerContainer {
    overflow: auto;
    /* margin-bottom: 20px; */
    margin-top: 0px;
    margin-right: 0px;

    .mapPicker {
        font-family:  "Avenir", Arial, Helvetica, sans-serif;
        float:left;
        margin: 0 1px;
        background-color: rgba(12,12,12,0.55);
        border-radius: 0px;
        color: #FFF;
        font-size: 1.2em;
        font-weight: bold;
        width:100px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        p{
            margin: 0;
        }
    }

    .mapPicker:first-child {
        border-radius: 5px 0px 0px 5px;
    }

    .mapPicker:last-child {
        margin-right: 10px;
        border-radius: 0px 5px 5px 0px;
    }

    .mapPicker.hidden {
        background-color:#0000005d;
        font-weight: 100;
    }
    
}

.mapPickerContainer:after {
    content: "";
    clear: both;
    display: table;
}