$borderRadius: 10px;
$height: 34px;
$background: rgba(12,12,12,0.55);

@-moz-document url-prefix() {
    .slidecontainer {
        input{
            top: 16px!important;
        }
    } 
}

#weatherDayPicker {
    overflow: visible;
    font-family: "Avenir", Arial, Helvetica, sans-serif;
    position: relative;

    .toggleButton {
        display:none;
        position: absolute;
        right: -$height;
        top:50%;
        margin-top: -$height/2;
        height: $height;
        width: $height;
        border-radius: 0 $borderRadius $borderRadius 0;
        background: $background;
        text-align: center;

        img {
            height:100%;
            opacity: 0.4;
        }
    }

    .closeButton {
        display:none;
        position: absolute;
        right: 40px;
        top:5px;
        height: 20px;
        line-height: 20px;
        width: 20px;
        border-radius: 50%;
        background: #eee;
        text-align: center;

        img {
            height:100%;
            opacity: 0.7;
        }
    }

    .weatherDateContainer {
        .futureDate {
            width:150px;
            margin:0 auto;
            display: block;
            font-family: "Avenir", Arial, Helvetica, sans-serif;
        }

        .monthPicker {
            overflow: auto;
            width: 312px;

            .months span {
                display: inline-block;
                overflow: wrap;
                height: 25px;
                line-height: 25px;
                width: 75px;
                cursor: pointer;
                text-align: center;
            }
            .months .active{
                color: rgb(255, 255, 255);
                border-radius: 5px;
                background-color: rgba(12,12,12,0.55);
            }
            

            .title {
                text-align: center;
            }
        }
    }

    .planner {
        border-radius: 10px;
        // background: rgba(12,12,12,0.55);
        background: rgba(35,35,36,0.85);
        color: #eee;
        overflow: hidden;
        margin-right: 5px;
        height: 130px;
    }

    .weatherDayContainer {
        float:left;
    }

    
    .header {
        line-height: 2.5em;
        font-weight: bold;
        text-align: center;
        margin: 0 10px;
    }
    .modeSwitchButton {
        width: 30px;
        overflow: hidden;
        height: 130px;
        line-height: 130px;
        text-align: center;
        background: rgba(35,35,36,0.85);
        color:#FFF;
        border-radius: 10px;
        cursor: pointer;
        position: relative;

        span {
            display: inline-block;
            transform: rotate(90deg);
            position: relative;
            left: -3px;
        }
    }

    .now {
        float:left;
        margin-right: 5px;
    }

    .modeSwitchButton:hover {
        opacity: 0.7;
    }
    .slidecontainer {
        background:rgba(0,0,0,0.301);
        overflow: hidden;
        padding-top: 8px;
        position: relative;
        height: 50px;


        .range-labels {
            //margin-bottom: 5px;
            padding: 0;
            list-style: none;
            overflow: auto;
            // margin-left:-20px;
            // margin:0 auto;
            // margin-top: -30px;
            // width:240px;
            position: absolute;
            left: 40px;
            top: 5px;
    
            li {
                position: relative;
                width: 40px;
                text-align: center;
                color: #b2b2b2;
                font-size: 14px;
                cursor: pointer;
                  pointer-events: none;
                float:left;
                z-index: 5000;
            }
        }

        .clock {
            position: absolute;
            top:0;
            left:0;
            font-size: 20px;
            top:50%;
            margin-top:-10px;
            height:20px;
            margin-left: 10px;

            i {
                position: absolute;
                top:0;
                left:0;
                margin:0;
                padding:0;
            }

            img {
                height:100%;
            }
        }   
        input {
            width:100%;
            z-index: 1000;
            position: absolute;
            top: 4px;
            left: 38px;
            
        }     

        input[type=range] {
            -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
            width: 100%; /* Specific width is required for Firefox. */
            background: transparent; /* Otherwise white in Chrome */
        }

        input[type=range]:focus {
            outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        }

        input[type=range]::-ms-track {
            width: 100%;
            cursor: pointer;
            /* Hides the slider so custom styles can be added */
            background: transparent; 
            border-color: transparent;
            color: transparent;
        }

        /* Special styling for WebKit/Blink */
        input[type=range]::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: 1px solid rgb(102,102,102);
            height: 40px;
            width: 40px;
            border-radius: 20px;
            background: rgba(17, 17, 17, 0.603);
            cursor: pointer;
            box-shadow: 0px 2px 5px rgba(0,0,0,0.211), 0px 0px 1px rgba(13,13,13,1); /* Add cool effects to your sliders! */
        }

        /* All the same stuff for Firefox */
        input[type=range]::-moz-range-thumb {
            border: 1px solid rgb(102,102,102);
            height: 40px;
            width: 40px;
            border-radius: 20px;
            background: rgba(17, 17, 17, 0.603);
            cursor: pointer;
            box-shadow: 0px 2px 5px rgba(0,0,0,0.211), 0px 0px 1px rgba(13,13,13,1); /* Add cool effects to your sliders! */
        }

        /* All the same stuff for IE */
        input[type=range]::-ms-thumb {
            border: 1px solid rgb(102,102,102);
            height: 40px;
            width: 40px;
            border-radius: 20px;
            background: rgba(17, 17, 17, 0.603);
            cursor: pointer;
            box-shadow: 0px 2px 5px rgba(0,0,0,0.211), 0px 0px 1px rgba(13,13,13,1); /* Add cool effects to your sliders! */
        }

        input[type=range]::-webkit-slider-runnable-track {
            width: 100%;
            height: 40px;
            cursor: pointer;
            //box-shadow: 0px 5px 5px #0000001e, 0px 0px 10px #27272718;
            border-radius: 30px;
            border: 1px solid rgba(1,1,1,0.192);
            background: rgba(129, 129, 129, 0.219);
        }
        
        input[type=range]::-moz-range-track {
            width: 100%;
            height: 40px;
            cursor: pointer;
            //box-shadow: 0px 5px 5px #0000001e, 0px 0px 10px #27272718;
            border-radius: 30px;
            border: 1px solid rgba(1,1,1,0.192);
            background: rgba(129, 129, 129, 0.219);
        }
        input[type=range]::-ms-track {
            width: 100%;
            height: 35px;
            cursor: pointer;
            //box-shadow: 0px 5px 5px #0000001e, 0px 0px 10px #27272718;
            border-radius: 30px;
            border: 1px solid rgba(1,1,1,0.192);
            background: rgba(129, 129, 129, 0.219);
        }

    }
    .weatherDay {
        overflow: auto;
        .day {
            float:left;
            line-height:50px;
            width:50px;
            text-align: center;
            cursor: pointer;
            border-radius: 10px 10px 0px 0px;
            .timePicker {
                display:none;
            }
        }

        
        .day:nth-child(1) {
            border-left: 0px solid #eee;
        }
        .day.selected {
            background:rgba(0,0,0,0.301);
            color: rgb(255, 255, 255);
            .timePicker {
                display: block;
            }
        }
    }

    .weatherWidget{
        height: 50px;
        margin-bottom:10px;
        position: absolute;
        top:-70px;
        width:0px; /* så man kan "dragge" ved siden af weather */
        overflow: visible;

        #windContainer{
            width: 60px;
            position: relative;
            left:10px;
            bottom:25px;
            opacity: 0.53;

            p {
                position: relative;
                top: -35px;
                transform: rotate(-90deg);
                color: rgb(255, 255, 255);
                font-size: 13px;
                font-weight: bold;
                margin:0;
                text-align: center;
            }

            img {
                width:100%;
            }
        }


        #weatherIcon {
            position: absolute;
            bottom: -15px;
            left: 80px;

            img {
                width: 60px;
            }
        }
        #temperatureText {
            position: absolute;
            bottom: -5px;
            left:130px;
            padding: 10px;
            h1 {
                color: #FFF;
                text-shadow: 0px 2px 4px #aaa;
                margin: 0;
                font-size: 1.4em;
                text-align: center;
            }
        }
        
    
    }
}

@media screen and (max-width: 600px) {
    #weatherDayPicker {
        transition: 0.5s all ease;
        left: -100%;
        .toggleButton {
            display:block;
        }
    }

    #weatherDayPicker.visible {
        left:0;
        .toggleButton {
            display:none;
        }

        .closeButton {
            display:block;
        }
    }
}

