$filterPicker-height: 329.59px;
$edge-margin: 10px;

.filterPickerContainer {
  transition: opacity 0.5s ease;
  position: absolute;
  right: $edge-margin;
  top: 50%;
  // height: $filterPicker-height;
  margin-top: -$filterPicker-height/2;
  width: 70px;
  border-radius: 10px;
  // background: rgba(12,12,12,0.55);
  color: #f8f8f8f8;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  z-index: 1500;
}

.smallSettingsContainer h3 {
  text-align: center;
  font-size: 1.4em;
}

.smallSettingsContainer:after {
  content: "";
  clear: both;
  display: table;
}
.smallSettingsContainer {
  width: 70px;
  background: rgba(35, 35, 36, 0.85);
  border-radius: 10px;
  padding: 5px;
  padding-top: 10px;
  /* margin-right: -10px; */
  color: rgba(248, 248, 248, 0.972);
  font-family: "Avenir", Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 768px) {
  .smallSettingsContainer {
    display: none;
  }
}

.smallSettingsContainer .settingsCard {
  width: 100%;
}

.smallSettingsContainer .card-body {
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 1px;
  padding-right: 1px;
}
